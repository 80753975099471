const ifAuthenticated = (to, from, next) => {
    if (localStorage.getItem("auth")) {
        next();
        return;
    }
    next('/');
};

const ifNotAuthenticated = (to, from, next) => {
    if (!localStorage.getItem("auth")) {
        next();
        return;
    }
    next('/dashboard');
};

const checkPreviousRoute = (to, from, next) => {
    if (from.path === '/signup') {
        next();
        return;
    }

    if (!localStorage.getItem("auth") && from.path === '/signup') {
        next();
        return;
    }
    next('/dashboard');
}

export default [
    // {didnPbx
    //     path: '*',
    //     redirect: '/dashboard'
    // },
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/',
        name: 'Login',
        component: () =>
            import (
                `@/pages/Authentication/login.vue`
            ),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: () =>
            import (
                `@/pages/Authentication/signup.vue`
            ),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/forgotPassword',
        name: 'ForgotPassword',
        component: () =>
            import (
                `@/pages/Authentication/forgotPassword.vue`
            ),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/emailVerification',
        name: 'emailVerification',
        component: () =>
            import (
                `@/pages/Authentication/emailVerification.vue`
            ),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/updateEmailVerification',
        name: 'updateEmailVerification',
        component: () =>
            import (
                `@/pages/Authentication/updateEmailVerification.vue`
            ),
       // beforeEnter: ifNotAuthenticated
    },
    {
        path: '/securityQuestion/:partyId/:email',
        name: 'SecurityQuestion',
        component: () =>
            import (
                `@/pages/Authentication/securityQuestion.vue`
            )
    },
    {
        path: '/updatePassword',
        name: 'UpdatePassword',
        component: () =>
            import (
                `@/pages/Authentication/updatePassword.vue`
            ),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/verifyUser',
        name: 'verifyUser',
        component: () =>
            import (
                `@/pages/Authentication/verifyUser.vue`
            ),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () =>
            import (
                `@/pages/Dashboard/dashboard.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/profile',
        name: 'Profile',
        component: () =>
            import (
                `@/pages/Profile/profile.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/purchasedid',
        name: 'Purchase DID',
        component: () =>
            import (
                `@/pages/PurchaseDID/purchasedid.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/shoppingcart',
        name: 'Shopping Cart',
        component: () =>
            import (
                `@/pages/ShoppingCart/shoppingcart.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/profileimage',
        name: 'Profile Image',
        component: () =>
            import (
                `@/pages/ProfileImage/profileimage.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/orders',
        name: 'Orders',
        component: () =>
            import (
                `@/pages/Orders/orders.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/order/:orderId',
        name: 'Orders Detail',
        component: () =>
            import (
                `@/pages/Orders/ordersdetail.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/callflow',
        name: 'Launch DID',
        component: () =>
            import (
                `@/pages/CallFlow/callflow.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ticket',
        name: 'Ticket',
        component: () =>
            import (
                `@/pages/Ticket/ticket.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/cdr',
        name: 'Call Detail Records',
        component: () =>
            import (
                `@/pages/CallDetailRecord/cdr.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ratelist',
        name: 'Rate List',
        component: () =>
            import (
                `@/pages/RateList/ratelist.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/ratelist/:rateId',
        name: 'Rate Detail',
        component: () =>
            import (
                `@/pages/RateList/rateListDetail.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/billingAcc',
        name: 'Billing Account',
        component: () =>
            import (
                `@/pages/billingAccount/recharge.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/transaction',
        name: 'Transaction Details',
        component: () =>
            import (
                `@/pages/billingAccount/transaction.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/payment',
        name: 'Payment',
        component: () =>
            import (
                `@/pages/billingAccount/payment.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/orderCancel',
        name: 'Order Cancel',
        component: () =>
            import (
                `@/pages/billingAccount/orderCancel.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/livecall',
        name: 'Live Call',
        component: () =>
            import (
                `@/pages/LiveCall/livecall.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/numberManager',
        name: 'Number Manager',
        component: () =>
            import (
                `@/pages/numberManager/numberManager.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/orderDetails/:id',
        name: 'Order Details',
        component: () =>
            import (
                `@/pages/billingAccount/orderDetails.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/device/',
        name: 'Device',
        component: () =>
            import (
                `@/pages/Device/device.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/smsLog',
        name: 'Inbound SMS Log',
        component: () =>
            import (
                `@/pages/Profile/smsLog.vue`
            ),
        beforeEnter: ifAuthenticated
    },
    // {
    //     path: '/didnPbx/',
    //     name: 'Didn Pbx',
    //     component: () =>
    //         import (
    //             `@/pages/CallFlow/didnPbx.vue`
    //         ),
    //     beforeEnter: ifAuthenticated
    // },
];